
<script setup>
// eslint-disable-next-line no-unused-vars
import appConfig from "@/app.config";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { reactive, onBeforeMount, watch } from 'vue'
import { callApi } from '../../../msal/msalApi'
import Swal from 'sweetalert2'
import store from '../../../state/store';
import axios from "axios";
const title = "Dashboard";
import { useRoute } from 'vue-router'


const data = reactive({
  userEmail: '',
  showLoading: false,
  loading: false,
  getEmail: '',
  currentPage: 1,
  itemsPerPage: 10,
  selectFranchise: '',
  selectLocation: '',
  inputSearch: '',
  info: '',
  info2: '',
  currentDate: null,
  DateOneYearAgo: null,
  totalResults: null,
  selectFranchises: '',
  selectSelections: '',
  franchiseLocations: [],
  franchiseSelections: [],

  selectedMetric: 20,
  franchisesData: null,
});

// Define the function to call the API with async/await
const callAPI = async () => {
  try {
    data.userEmail = store.getters.getEmail || '';
    data.showLoading = true;
    data.loading = true;

    let url = `${process.env.VUE_APP_WLT_API}Associate/PracticeGroupMembersPaged?email=${data.userEmail}&page=${data.currentPage}&pageSize=${data.itemsPerPage}`;

    if (data.selectLocation && data.selectFranchise) {
      url += `&franchiseLocationIdFilters[0]=${data.selectLocation}&franchiseLocationIdFilters[1]=${data.selectFranchise}`
    }
    if (data.selectFranchise) {
      url += `&franchiseIdFilters[0]=${data.selectFranchise}`;
    }
    if (data.selectLocation) {
      url += `&franchiseLocationIdFilters[0]=${data.selectLocation}`;
    }

    const response2 = await callApi(url, store.getters.getToken);
    const { totalItems, franchiseLocations, franchiseSelections } = response2;
    data.totalResults = totalItems;
    data.franchiseLocations = franchiseLocations;
    data.franchiseSelections = franchiseSelections;


    console.log("-----------------------------------------")


    console.log(franchiseLocations, franchiseSelections)
    sendInformation()
  } catch (error) {
    console.error(error.message);
    error.message = 'Error obtaining the list';
    Swal.fire(`<p class="h5">${error.message}</p>`, '', 'error');
  } finally {
    data.loading = false;
    data.showLoading = false;
  }
};

const loadFranchisesData = async () => {
  const countType = data.selectedMetric; // Map selected metric to countType (0, 10, 20)
  const returnTopN = 5; // Assuming you want the top 5 franchises
  try {
    const endPoint = `${process.env.VUE_APP_WLT_API}Dashboard/FranchiseClientCount?countType=${countType}&returnTopN=${returnTopN}`;
    const response = await callApi(endPoint, store.getters.getToken);
    data.franchisesData = response; // Assuming your API response structure matches your data needs
  } catch (error) {
    console.error(error);
    // Handle errors appropriately
  }
};
// Function to send information
async function sendInformation() {
  const endPoint = `${process.env.VUE_APP_WLT_API}/Dashboard/ClientCounts`;
  const apiKey = store.getters.getToken;

  const createPostData = (franchise, location, reportDate) => ({
    franchises: franchise ? [franchise] : [],
    locations: location ? [location] : [],
    reportDates: [reportDate]
  });

  const postData = createPostData(data.selectFranchise, data.selectLocation, getCurrentDate());
  const postData2 = createPostData(data.selectFranchise, data.selectLocation, getDateOneYearAgo());

  try {
    // Use Promise.all to make two concurrent API calls
    const [response1, response2] = await Promise.all([
      axios.post(endPoint, postData, { headers: { Authorization: `Bearer ${apiKey}` } }),
      axios.post(endPoint, postData2, { headers: { Authorization: `Bearer ${apiKey}` } })
    ]);

    // Handle the data from the first API call
    data.info = response1.data.length > 0 ? response1.data[0] : {};

    // Handle the data from the second API call
    data.info2 = response2.data.length > 0 ? response2.data[0] : {};

    console.log('Successful POST requests. Responses:', data.info);
  } catch (error) {
    console.error('Error sending POST requests:', error);
  }
}

function handleSelectFranchiseChange(franchise) {
  const selectedFranchise = franchise.target ? franchise.target.value : franchise;
  data.selectFranchise = selectedFranchise || '';
  sendInformation();
  callAPI();
}


function handleSelectLocationChange(location) {
  data.selectLocation = location.target.value;
  sendInformation()
  callAPI();

}

function getDateOneYearAgo() {
  const currentDate = new Date();
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

  const formattedDate = oneYearAgo.toISOString();
  console.log(formattedDate)

  return formattedDate;
}



function getCurrentDate() {
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();
  console.log(formattedDate)
  return formattedDate;
}



watch(() => data.selectedMetric, () => {
  loadFranchisesData();
});

onBeforeMount(() => {
  const route = useRoute();

  route.query.selectedMetric ? data.selectedMetric = route.query.selectedMetric : data.selectedMetric = 20
  route.query.selectFranchise ? data.selectFranchise = route.query.selectFranchise : data.selectFranchise = ""
  callAPI()

  if (!data.franchisesData) {
    // Load data only if it hasn't been loaded before
    loadFranchisesData();
  }
});
</script>


<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="container-fluid bg-white pb-3">
      <form action="" class="pt-3">
        <div class="d-flex">

          <select v-if="data.franchiseSelections.length > 1" name="franchiseSelect" id=""
            class="col fw-bold mx-2 form-control" @change="handleSelectFranchiseChange" v-model="data.selectFranchise">
            <!-- Corregido: utiliza data.selectFranchise como v-model -->
            <option value="" selected>All Practice Group</option>
            <option :value="franchise.franchise_ID" v-for="(franchise, index) in data.franchiseSelections" :key="index">
              {{ franchise.franchiseName }}
            </option>
          </select>


          <select v-if="data.franchiseSelections.length === 1" name="franchiseSelect" id=""
            class="col fw-bold mx-2 form-control" @change="handleSelectFranchiseChange"
            v-model="data.franchiseSelections[0].franchise_ID">
            <!-- Corregido: utiliza data.selectFranchise como v-model -->
            <option selected :value="data.franchiseSelections[0].franchise_ID">
              {{ data.franchiseSelections[0].franchiseName }}
            </option>
          </select>

          <select v-if="data.franchiseLocations.length > 1" name="selectLocation" id="" class="col fw-bold form-select"
            @change="handleSelectLocationChange" v-model="data.selectLocation">
            <!-- Corregido: utiliza data.selectLocation como v-model -->
            <option value="" selected>All Locations</option>
            <option :value="franchise.franchiseLocation_ID" v-for="(franchise, index) in data.franchiseLocations"
              :key="index">
              {{ franchise.locationName }}
            </option>
          </select>

          <select v-if="data.franchiseLocations.length === 1" name="selectLocation" id="" class="col fw-bold form-select"
            @change="handleSelectLocationChange" v-model="data.franchiseSelections[0].franchiseLocation_ID">
            <option value="" selected>All Locations</option>
            <option :value="data.franchiseSelections[0].franchiseLocation_ID">
              {{ data.franchiseLocations[0].locationName }}
            </option>
          </select>


        </div>
      </form>

    </div>

    <div class="row">
      <div class="col">
        <div class="border border-light  card mt-3" style="width: 35rem;">
          <div class="card-body ">
            <h5 class="card-title text-center fs-4 text-primary">Clients</h5>
            <h6 class="card-subtitle mb-2 text-body-secondary text-center fs-6">This Year</h6>


            <div class="row text-center">
              <div class="col">
                <div class="gray border border-light ">
                  <div class="bg-white fs-5 border-bottom border-light  p-2">TTL Clients</div>
                  <p class=" text-warning fw-bold fs-4">{{ data.info.total }}</p>
                </div>
              </div>
              <div class="col">
                <div class=" gray border border-light ">
                  <div class=" bg-white fs-5 border-bottom border-light  p-2">
                    New Clients (Past 30 Days)
                  </div>
                  <p class=" text-warning fw-bold fs-4">{{ data.info.weightLossLast30 }}</p>
                </div>
              </div>
            </div>
            <h6 class="card-subtitle mb-2 text-body-secondary text-center mt-2">Same Period Last Year</h6>
            <div class="row text-center">
              <div class="col">
                <div class="gray border border-light ">
                  <div class="bg-white fs-5 border-bottom border-light  p-2">TTL Clients</div>
                  <p class=" text-warning fw-bold fs-4">{{ data.info2.total }}</p>
                </div>
              </div>
              <div class="col">
                <div class="gray border border-light ">
                  <div class="bg-white fs-5 border-bottom border-light  p-2">
                    New Clients (Past 30 Days)
                  </div>
                  <p class=" text-warning fw-bold fs-4">{{ data.info2.weightLossLast30 }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col">
        <div class="border border-light card mt-3" style="width: 35rem;">
          <div class="card-body ">
            <h5 class="card-title text-center fs-4 text-primary">Top 5 Licensees</h5>
            <select v-model="data.selectedMetric" class="form-select text-center border " @change="loadFranchisesData">
              <option value="20">Top 5 New Clients</option>
              <option value="0">Top 5 Total Clients</option>
              <option value="10">Top 5 Active Clients</option>
            </select>

            <!-- Display Franchises Data -->
            <table class="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody v-if="data.franchisesData">
                <tr v-for="(franchise, index) in data.franchisesData" :key="index">
                  <th scope="row">{{ index + 1 }}</th>

                  <td class="fw-bold">
                      <a class="ellipsis" href="#" :title="`Franchise: ${franchise.franchiseName} \nCity: ${franchise.city} \nState: ${franchise.state}`" @click="handleSelectFranchiseChange(franchise.franchise_ID)">{{  franchise.franchiseName }}</a>
                  </td>

                  <td>
                    <router-link class="fw-bold fs-5"
                            :to="{ path: '/client', query: { selectFranchise: franchise.franchise_ID } }">{{ franchise.totalCount }}</router-link>

                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-center">
              <router-link class="fs-5" :to="{ path: '/viewAll',query: {'selectedMetric':data.selectedMetric} }">View All</router-link>

            </div>
          </div>
        </div>
      </div>
    </div>




  </Layout>
</template>


<style>
.gray {
  background-color: #f2f2f2;
  /* Puedes ajustar el código hexadecimal según tus preferencias */
  /* Añade relleno según sea necesario */
}

.ellipsis {
  white-space: nowrap;
  overflow: visible;
  max-width: 200px;
  position: relative;

}

.ellipsis:hover::before {
  content: attr(title);
  white-space: pre;
  display: block;
  position: absolute;
  top: 0%;
  left: 100px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 1;
  color: var(--bs-primary);
  /* Usa el color primario definido por Bootstrap */
}
</style>